var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('SSRTable',{ref:"login-list",attrs:{"data-url":_vm.loadUrl,"data-key":'records',"schema":_vm.schema},on:{"load-error":_vm.onLoadError},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.moment.utc(item.created_at).format('YYYY/MM/DD HH:mm:ss'))+" ")])}},{key:"merchantId",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.merchant_id))])])}},{key:"clientIp",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.client_ip))])])}},{key:"userType",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.user_type))])])}},{key:"country",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.country))])])}},{key:"devices",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.devices))])])}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
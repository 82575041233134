<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <!-- LIST OF MERCHANTS -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <SSRTable ref="login-list" :data-url="loadUrl" :data-key="'records'" :schema="schema" @load-error="onLoadError">
              <div slot="createdAt" slot-scope="{item}" class="py-1">
                {{ moment.utc(item.created_at).format('YYYY/MM/DD HH:mm:ss') }}
              </div>
              <div slot="merchantId" slot-scope="{item}" class="py-1">
                <span>{{ item.merchant_id }}</span>
              </div>
              <div slot="clientIp" slot-scope="{item}" class="py-1">
                <span>{{ item.client_ip }}</span>
              </div>
              <div slot="userType" slot-scope="{item}" class="py-1">
                <span>{{ item.user_type }}</span>
              </div>
              <div slot="country" slot-scope="{item}" class="py-1">
                <span>{{ item.country }}</span>
              </div>
              <div slot="devices" slot-scope="{item}" class="py-1">
                <span>{{ item.devices }}</span>
              </div>
            </SSRTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import moment from 'moment';

export default {
  components: {
    SSRTable,
  },
  methods: {
    onLoadError(err){
      this.$notify({
        text: err.message,
        type: 'error'
      });
    }
  },
  data() {
    return {
      moment,
    };
  },
  computed: {
    loadUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/loginmanagement/users`
    },
    schema() {
      let ret = [
        { value: 'createdAt', text: this.$t('views.loginmanagement.loginRecords.createdAt') },
        { value: 'merchantId', text: this.$t('views.loginmanagement.loginRecords.merchantId'), sortable: false },
        { value: 'clientIp', text: this.$t('views.loginmanagement.loginRecords.clientIp'), sortable: false },
        { value: 'userType', text: this.$t('views.loginmanagement.loginRecords.userType'), sortable: false },
        { value: 'country', text: this.$t('views.loginmanagement.loginRecords.country'), sortable: false },
        { value: 'device', text: this.$t('views.loginmanagement.loginRecords.device'), sortable: false }
      ];
      return ret;
    }
  }
}
</script>
